.adder {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;

  .editor {
    flex-grow: 1;

    iframe {
      height: 100% !important;
    }
  }

  > button {
    height: 25px;
    width: 140px;
    margin-top: 5px;
    margin-left: auto;
  }
}
