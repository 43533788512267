.icon {
  display: flex;
  svg {
    width: 25px;
    height: 25px;
  }
}

.popup {
  position: relative;
  width: 310px;
  height: 120px;
  padding: 10px;
  border-radius: 3px;

  .close {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;

    svg {
      height: 15px;
      width: 15px;
    }
  }

  .upper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > span {
      font-size: 18px;
      color: #333;
      margin-bottom: 10px;
      margin-top: 5px;
    }

    .checkmark {
      display: block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #8cc63f;
      color: #ffffff;
      font-size: 20px;
      line-height: 40px;
      text-indent: 10px;
    }

    .checkmark:before {
      content: '\2713';
    }
  }

  .lower {
    display: flex;
    justify-content: center;
    flex-flow: row;

    > button {
      width: 60px;
      padding: 8px 10px;
      margin-left: 5px;
      background-color: #858585;
      color: #fff;
      border-radius: 3px;
      border: none;
      outline: none;
      text-decoration: none;
      text-align: center;
      cursor: pointer;
    }

    > input {
      font-size: 12px;
      color: #666666;
      flex-grow: 1;
    }
  }
}
