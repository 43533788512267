.textAreaWrap {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  border-radius: 4px;

  .textarea {
    width: 100%;
    margin-top: 3px;
    &:focus-within {
      border-color: var(--primary);
    }

    textarea {
      resize: none;
      width: 100%;
    }
  }

  &.disabled {
    cursor: not-allowed;

    .textarea {
      background-color: #eeeeee;
      border: 1px solid #cccccc;
      color: #999999;

      textarea {
        cursor: not-allowed;
      }
    }
  }

  .textLabel {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
  }
}
