.options {
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  cursor: pointer;

  svg {
    width: 10px;
    height: 10px;

    path {
      fill: var(--primary);
    }
  }

  > span {
    margin-left: 3px;
    color: var(--primary);
    font-size: 14px;

    &:hover {
      text-decoration: underline;
    }
  }
}

.popup {
  height: fit-content;
  width: 295px;
  background-color: #ffffff;
  border: 1px solid rgba(61, 70, 77, 0.1);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);

  @media (max-width: 601px) {
    position: absolute;
    right: -40px;
    top: -20px;
  }

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 20px;
    padding: 5px;
    background-color: #efefef;

    h5 {
      color: var(--primary);
      font-size: 13px;
    }

    svg {
      width: 10px;
      height: 10px;
      cursor: pointer;
    }
  }
  .optionsGroup {
    padding: 15px;
    .caseType {
      margin-bottom: 5px;
      h6 {
        margin-bottom: 5px;
        font-size: 12px;
        color: #999999;
      }

      .radio {
        font-size: 12px;
        color: #999999;
      }
    }

    .dropdown {
      width: 100%;
      margin-top: 10px;
      font-size: 12px;
      > span {
        width: 100%;
      }
    }
  }
}
