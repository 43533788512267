.listItem {
  display: flex;
  flex-flow: column nowrap;
  height: fit-content;
  order: 2;
  margin: 0 5px 10px 0;
  padding: 10px;
  background-color: #f7f7f7;
  transition: all 300ms ease;
  border-radius: 2px;
  cursor: pointer;

  &:hover {
    background-color: #e6e6e6;
  }

  &.folder {
    order: 1;
  }

  .upper {
    display: inline-flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    h6 {
      max-width: 50%;
      font-size: 13px;
      color: #000000;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .right {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #999999;

      .arrow {
        margin-left: 10px;
        svg {
          width: 20px;
          height: 20px;
        }
      }

      .newCase {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        width: 80px;
        height: 20px;
        border-radius: 40px;
        background-color: var(--primary);
        color: #ffffff;
        font-size: 11px;
        margin-right: 8px;
        margin-left: 8px;

        .newCaseIcon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 12px;
          height: 12px;
          background-color: #ffffff;
          border-radius: 50%;
          color: var(--primary);
          font-size: 17px;

          &:before {
            content: '\002B';
            padding-top: 3px;
          }
        }
      }
    }
  }

  .lower {
    display: none;

    &.show {
      display: inline-flex;
    }

    > p {
      margin: 0;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-size: 12px;
      color: #666666;
      text-align: left;
    }
  }
}
