.dropdownWrap {
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  min-width: 0;
  max-width: 50%;
  height: 100%;
  margin-bottom: 10px;

  .titlewrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: 40px;
    color: var(--primary);
    font-size: 18px;
  }

  .labelwrapper {
    max-width: 100%;
    height: 13px;
    min-width: 0;
    margin-bottom: 5px;

    > label {
      display: inline-block;
      width: 100%;
      min-width: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 12px;
      color: #666666;
    }
  }

  .dropdown {
    margin-top: 6px;
    width: 100%;
    height: 25px;
    box-shadow: none;
    border-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    color: #adadad;
    font-size: 12px;
  }

  .dropdown.error > span {
    border-color: #ee3557;
  }

  &.disabled {
    cursor: not-allowed;

    .dropdown > span {
      background-color: #eeeeee;
      border: 1px solid #cccccc;
      color: #444444;
    }
  }

  .inputSection {
    display: flex;
    flex-flow: row nowrap;

    .question__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 6px;
      height: 23px;
      color: #999999;
      border-width: 1px 1px 1px 0px;
      border-color: #cccccc;
      border-style: solid;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      cursor: pointer;

      > svg {
        width: 20px;
        height: 20px;
      }
    }

    .disabled {
      pointer-events: none;
      opacity: 0.7;
    }
  }
}
