.skeletonContainer {
    height: 100%;
    .item {
      grid-area: relationList;
      display: flex;
      height: 40px;
      padding: 10px;
  
      > div {
        display: flex;
        flex: 1;
      }
    }
  }
  