.searchWrap {
  display: flex;
  flex-flow: column nowrap;

  .textLabel {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 70vw;
  }

  > span {
    width: 100%;
  }

  &.disabled {
    cursor: not-allowed;

    .autoComplete {
      background-color: #eeeeee;
      border: 1px solid #cccccc;
      color: #444444;
    }
  }
}

.searchResult {
  font-size: 12px;
  color: #999999;
}
