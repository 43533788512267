.button {
  padding-top: 19px;

  .buttonText {
    display: block;
    height: 25px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    text-align: center;
    padding-top: 2px;
    border: 1px solid var(--primary);
    background-color: transparent;
    color: var(--primary);
  }
}

.iframe {
  height: 99%;
  .external {
    display: flex;
    align-items: flex-end;

    .externalHint {
      margin: 0;
      font-size: 12px;
      color: #999999;
    }

    span {
      color: var(--primary);
      text-decoration: underline;
      cursor: pointer;
      margin-left: 5px;
    }
  }
}
