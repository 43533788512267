.treeWrap {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  position: relative;

  input {
    width: 100%;
    margin-top: 3px;
    cursor: pointer !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  svg {
    position: absolute;
    right: 8px;
    top: 30px;
    width: 10px;
    height: 10px;
    cursor: pointer;
  }

  &.disabled {
    cursor: not-allowed;

    input {
      background-color: #eeeeee;
      border: 1px solid #cccccc;
      color: #999999;
    }
  }
  .textLabel {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
  }
}
