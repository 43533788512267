.animateContainer {
  z-index: 1 !important;

  > div {
    height: 100%;
  }

  .helper {
    display: flex;
    flex-flow: column nowrap;
    max-width: 260px;
    height: calc(100% - 40px);
    background-color: #ffffff;
    padding: 35px 5px 5px 20px;

    .title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding-right: 15px;
      margin-bottom: 10px;

      h4 {
        font-size: 18px;
      }

      .icon {
        cursor: pointer;

        svg {
          height: 12px;
          width: 12px;
        }
      }
    }
  }
}
