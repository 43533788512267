.container {
    display: flex;
    flex-flow: column nowrap;
    flex-grow: 1;
    overflow: auto;
  
    .file {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 30px;
      width: calc(100% - 10px);
      padding: 5px;
      border-bottom: 1px solid #ccc;
      color: #666666;
  
      &:hover {
        background-color: #e6e6e6;
      }
  
      .date {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        width: 15%;
  
        .full {
          max-width: 100%;
          font-size: 10px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
  
        .hour {
          max-width: 100%;
          font-size: 11px;
          font-weight: bold;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
  
      .text {
        display: flex;
        flex-flow: column nowrap;
        flex: 1;
        min-width: 0;
  
        .name {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          flex: 1;
          max-width: 80%;
          min-width: 0;
          justify-content: flex-start;
          padding: 0;
          margin-top: 14px;
          color: #666666;
          font-size: 12px;
          text-decoration: none;
  
          cursor: pointer;
          &:hover {
            color: var(--primary);
          }
        }
  
        .description {
          flex-grow: 1;
          max-width: 80%;
          font-size: 10px;
          color: #999999;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
  
      .right {
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 8px;
  
        .size {
          font-size: 11px;
          white-space: nowrap;
        }
  
        .status {
          display: flex;
          align-items: center;
          width: fit-content;
          padding: 3px 5px;
          border-radius: 10px;
  
          &.public {
            background-color: var(--primary);
          }
  
          &.private {
            background-color: #999999;
          }
  
          > span {
            color: var(--contrast);
            font-size: 10px;
            font-weight: 400;
          }
        }
  
        > svg {
          width: 15px;
          min-width: 15px;
          height: 15px;
          cursor: pointer;
  
          path {
            fill: #818285;
          }
        }
      }
    }
  
    .noAttachments {
      display: flex;
      flex-flow: column;
      flex-grow: 1;
      align-items: center;
      justify-content: center;
  
      > span {
        color: #ccc;
        font-size: 12px;
      }
      svg {
        height: 40px;
        width: 40px;
  
        path {
          fill: #ccc !important;
        }
      }
    }
  }
  