.section {
    .header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 12px 15px;
      margin-bottom: 10px;
      border-radius: 3px;
      background-color: #efefef;
      cursor: pointer;
  
      > h2 {
        color: #333333;
        font-size: 14px;
        user-select: none;
        font-weight: bold;
      }
  
      > svg polygon {
        fill: #333333;
      }
    }
    .desc {
      font-size: 13px;
      margin: 0 0 10px 0;
      padding: 0;
      color: #999999;
    }
  }
  