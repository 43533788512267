.k-animation-container.k-list-container,
.k-animation-container.k-animation-container-shown,
.k-animation-container.k-animation-container-relative {
  z-index: 10002 !important;
}

.k-text-selection ::selection,
.k-chart ::selection,
.k-sparkline ::selection,
.k-stockchart ::selection,
kendo-scrollview.k-scrollview-wrap ::selection,
div.k-scrollview ::selection,
.k-pdf-viewer ::selection,
.k-mediaplayer ::selection,
.k-chat ::selection,
.k-scheduler ::selection,
.k-spreadsheet ::selection,
.k-splitter ::selection,
.k-card ::selection,
.k-panelbar ::selection,
.k-pager-wrap ::selection,
.k-breadcrumb ::selection,
.k-notification ::selection,
.k-drawer ::selection,
.k-window ::selection,
.k-rating ::selection,
.k-maskedtextbox ::selection,
.k-multiselect ::selection,
.k-dropdowntree ::selection,
.k-dropdown ::selection,
.k-dateinput ::selection,
.k-datepicker ::selection,
.k-datetimepicker ::selection,
.k-timepicker ::selection,
.k-combobox ::selection,
.k-flatcolorpicker ::selection,
.k-numerictextbox ::selection,
.k-progressbar ::selection,
.k-pane-wrapper ::selection,
.k-view ::selection,
.k-block ::selection,
.k-panel ::selection {
  background-color: var(--primary);
  color: #ffffff;
}
.k-textarea .k-input::selection,
.k-textbox::selection,
.k-textarea::selection {
  background-color: var(--primary);
}

.k-window {
  border-radius: 10px;
}

.k-window .k-window-title {
  line-height: normal;
  color: var(--primary);
  font-size: 16px;
}

.k-textbox:focus,
.k-textbox.k-state-focus,
.k-textbox.k-state-focused,
.k-textarea:focus,
.k-textarea.k-state-focus,
.k-textarea.k-state-focused {
  border-color: var(--primary);
}

.k-button {
  border-radius: 3px;
}

.k-button:hover {
  opacity: 0.8;
}

.k-button.k-button-solid-primary {
  background-color: var(--primary);
  background-image: linear-gradient(var(--rgb), var(--rgb));
  border-color: var(--primary);
}

.k-button.k-button-solid-primary .k-button-text {
  color: #fff;
}

.k-buttonk-button-solid-primary .k-outline:hover,
.k-buttonk-button-solid-primary .k-outline.k-state-hover {
  border-color: var(--primary);
  background-color: var(--primary);
}

.k-buttonk-button-solid-primary :hover,
.k-buttonk-button-solid-primary .k-state-hover {
  border-color: var(--primary);
  color: #fff;
  background-color: var(--primary);
  background-image: linear-gradient(var(--rgb), var(--rgb));
}

.k-button.k-button-solid-primary:focus,
.k-button.k-button-solid-primary.k-state-focused {
  box-shadow: none;
}

.k-button-group .k-button:active,
.k-button-group .k-button.k-state-active,
.k-button-group .k-button-solid-base.k-selected,
.k-button-group > input[type='radio']:checked + .k-button,
.k-button-group > input[type='checkbox']:checked + .k-button {
  border-color: var(--primary);
  background-color: var(--primary);
  background-image: linear-gradient(var(--rgb), var(--rgb));
}

.k-stepper .k-step-done.k-disabled .k-step-indicator,
.k-list-optionlabel:hover.k-state-selected,
.k-list-optionlabel.k-state-hover.k-state-selected,
.k-list-item.k-selected,
.k-list-item.k-selected:hover,
.k-selected.k-list-optionlabel:hover,
.k-list-optionlabel.k-state-selected {
  background-color: var(--primary);
  border: 0;
}

.k-tabstrip-items .k-item {
  color: #999999;
  border-width: 3px;
}

.k-avatar-icon,
.k-avatar-initials {
  background-color: var(--primary);
}

.k-tabstrip-items .k-item:hover,
.k-tabstrip-items .k-item.k-state-hover {
  color: var(--primary);
}

.k-tabstrip-top > .k-tabstrip-items-wrapper .k-item {
  border: 0;
  margin: 0;
  border-bottom: 2px solid transparent;
  background: transparent;
  padding-top: 5px;
}

.k-tabstrip-items .k-item.k-state-active,
.k-tabstrip-items .k-item.k-state-selected {
  border-bottom-color: initial !important;
  color: var(--primary);
  border-bottom: 2px solid var(--primary);
}

.k-stepper .k-step-error:hover .k-step-label,
.k-stepper .k-step-error.k-step-hover .k-step-label,
.k-drawer {
  color: var(--primary);
}

.k-stepper .k-step-done:hover .k-step-indicator,
.k-stepper .k-step-done.k-step-hover .k-step-indicator,
.k-stepper .k-step-current:hover .k-step-indicator,
.k-stepper .k-step-current.k-step-hover .k-step-indicator,
.k-drawer-item.k-state-selected:hover,
.k-drawer-item.k-state-selected.k-state-hover,
.k-drawer-item.k-state-selected {
  background-color: var(--primary);
}

.k-listview {
  border-color: transparent;
}

.k-stepper .k-step-done .k-step-indicator,
.k-stepper .k-step-current .k-step-indicator,
.k-stepper .k-step-current.k-disabled .k-step-indicator {
  border-color: var(--primary);
  background-color: var(--primary);
}

.k-progressbar .k-selected {
  border-color: var(--primary);
  background-color: var(--primary);
}

.k-stepper .k-step-error .k-step-label {
  color: var(--primary);
}

.k-stepper .k-step-success .k-step-label {
  color: var(--primary);
}

.k-stepper .k-step-disabled .k-step-label {
  color: #a3a3a3;
}

.k-stepper .k-step-current.k-step-disabled .k-step-indicator,
.k-stepper .k-step-current:disabled .k-step-indicator {
  border-color: var(--primary);
  background-color: var(--primary);
}

.k-tabstrip-top > .k-tabstrip-items-wrapper .k-item.k-state-active,
.k-tabstrip-top > .k-tabstrip-items-wrapper .k-item.k-active {
  border-color: var(--primary);
}

.k-stepper .k-step-done.k-step-disabled .k-step-indicator,
.k-stepper .k-step-done:disabled .k-step-indicator {
  border-color: var(--primary);
  background-color: var(--primary);
  filter: contrast(30%);
}

.k-textbox,
.k-autocomplete.k-header,
.k-autocomplete,
.k-input {
  border-radius: 3px;
}

.k-checkbox:checked,
.k-radio:checked {
  border-color: var(--primary);
  background-color: var(--primary);
}

.k-radio:checked:focus {
  border-color: var(--primary);
  box-shadow: none;
}

.k-checkbox:checked:focus {
  border-color: var(--primary);
  box-shadow: none;
}

.k-dialog-titlebar {
  padding: 12px 16px;
  background-color: #f2f2f2;
  color: var(--primary);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.k-chat .k-message-box .k-button:hover {
  color: var(--primary);
}

.k-chat .k-alt .k-bubble {
  border-color: var(--primary);
  background-color: var(--primary);
}

.k-treeview-leaf.k-selected {
  background-color: var(--primary);
}

.k-rating-item.k-state-selected,
.k-rating-item:hover,
.k-rating-item.k-state-hover,
.k-rating-item.k-state-selected,
.k-rating-item.k-selected {
  color: #fecc17;
}

.k-calendar .k-calendar-td.k-state-selected .k-link,
.k-calendar .k-calendar-td.k-state-selected:hover .k-link {
  border-color: var(--primary);
  background-color: var(--primary);
}

.k-calendar-nav .k-nav-today,
.k-calendar .k-footer .k-nav-today,
.k-calendar .k-calendar-header .k-today,
.k-calendar .k-nav-today:hover {
  color: var(--primary);
}

.k-calendar .k-calendar-navigation li:hover,
.k-calendar .k-calendar-navigation li.k-state-hover {
  color: var(--primary);
}

.k-time-header .k-time-now:hover,
.k-time-selector-header .k-time-now:hover,
.k-time-list .k-item:hover,
.k-time-header .k-time-now,
.k-calendar .k-content .k-today,
.k-calendar .k-calendar-nav-today,
.k-calendar .k-calendar-nav-today:hover {
  color: var(--primary);
}

.k-calendar .k-state-selected.k-state-hover .k-link,
.k-calendar .k-state-selected:hover .k-link,
.k-calendar .k-calendar-td.k-state-selected .k-link,
.k-calendar .k-calendar-td.k-selected .k-link {
  border-color: var(--primary);
  background-color: var(--primary);
}

.k-loader-primary {
  color: var(--primary);
}

.k-loader-primary .k-loader-segment::after {
  background-color: var(--primary);
}

.k-chat .k-alt .k-state-selected .k-bubble {
  box-shadow: none;
}

.k-chat-toolbar .k-button:hover,
.k-chat .k-toolbar-box .k-button:hover {
  color: var(--primary);
}

.k-menu:not(.k-context-menu) > .k-item {
  color: #ffffff;
}

.k-menu:not(.k-context-menu) > .k-item:hover,
.k-menu:not(.k-context-menu) > .k-item .k-state-active,
.k-menu:not(.k-context-menu) > .k-item.k-state-hover {
  color: #ffffff;
}

.k-picker,
.k-multiselect,
.k-dropdown .k-dropdown-wrap,
.k-dropdowntree .k-dropdown-wrap {
  background-color: #ffffff;
  background-image: unset;
  border: 1px solid #cccccc;
}

.k-popup {
  background-color: #ffffff;
}

.k-notification-wrap {
  width: 100%;
}

.k-notification-error {
  background-color: #ee3557;
  border-color: #ee3557;
}

.k-notification-warning {
  border-color: #fdc164;
  background-color: #fdc164;
}

.k-notification-success {
  border-color: #38b55e;
  background-color: #38b55e;
}

.k-notification-info {
  border-color: #27a9e1;
  background-color: #27a9e1;
}

.k-button.k-button-solid-primary.k-outline:active,
.k-button.k-button-solid-primary.k-outline.k-state-active {
  border-color: var(--primary);
  color: #ffffff;
  background-color: var(--primary);
  background-image: none;
  box-shadow: none;
}

.k-button.k-button-solid-primary.k-outline:focus,
.k-button.k-button-solid-primary.k-outline.k-state-focused {
  box-shadow: none;
}

.k-drawer-overlay .k-drawer,
.k-notification-group {
  z-index: 10001;
}

.k-stepper {
  grid-template-rows: repeat(16, 1fr) !important;
}
