.surveySkeleton {
  display: grid;
  grid-template-rows: 60px 25px 15px 140px 164px 164px 164px;
  row-gap: 10px;

  > div:nth-child(2) {
    width: 50%;
  }

  > div:nth-child(4) {
    justify-self: end;
  }

  .mainHeader {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    color: #bbbbbb;
    font-size: 13px;
    margin-bottom: 20px;

    svg {
      height: 55px;
      width: 40px;
      margin-right: 6px;
      margin-bottom: 5px;

      rect,
      polygon,
      path {
        fill: #bbbbbb;
      }
    }
  }

  .field {
    display: grid;
    grid-template-rows: 15px 1fr;
    row-gap: 10px;
    > div:first-child {
      width: 50%;
    }
  }
}
