.checkboxWrap {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;

  .checkbox {
    margin-top: 3px;

    &.error > input {
      border-color: #ee3557;
    }
  }

  &.disabled {
    cursor: not-allowed;
  }

  .textLabel {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
  }
}
