.project {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  height: 290px;
  width: 200px;
  padding: 10px;
  margin: 0px 11px 11px 0px;
  background-color: #f5f5f5;
  border-radius: 3px;
  transition: all 300ms ease;
  cursor: pointer;

  &:hover {
    background-color: #e6e6e6;
  }

  .image {
    width: 150px;
    height: 150px;
    margin-bottom: 5px;
  }

  .info {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;

    h3 {
      align-self: center;
      max-width: 90%;
      color: var(--primary);
      font-size: 15px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    p {
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-size: 12px;
      color: #666666;
      text-align: left;
    }
  }
}
