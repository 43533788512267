.multipleChoice {
  display: flex;
  flex-flow: column nowrap;
  .radioGroup {
    margin-top: 3px;

    label {
      font-size: 12px;
    }
  }

  .hint {
    color: #999999;
  }
}
