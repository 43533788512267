.linkWrap {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;

  .link {
    width: 100%;
    margin-top: 3px;
    position: relative;

    .linkInput {
      width: 100%;
      padding-right: 25px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 100%;
    }

    .linkIcon {
      position: absolute;
      right: 5px;
      top: 5px;
      cursor: pointer;
    }
  }

  &.disabled {
    cursor: not-allowed;

    .linkInput {
      background-color: #eeeeee;
      border: 1px solid #cccccc;
      color: #444444;
    }
  }
  .textLabel {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
  }
}
