.addFields {
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  height: 100%;

  .risk {
    height: 100px !important;
    overflow: hidden !important;
  }

  &.saveRow > form > div {
    height: calc(100% - 25px);
    overflow: auto;
  }
}
