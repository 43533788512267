.article {
  display: grid;
  grid-template-rows: 40px 1fr;
  height: 100%;
  background-color: #f2f2f2;

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary);
    border-bottom: 2px solid var(--primary);

    svg {
      height: 15px;
      width: 15px;
      margin-right: 3px;
      path {
        fill: var(--primary);
      }
    }
    h3 {
      font-size: 12px;
    }
  }

  .content {
    display: grid;
    grid-template-rows: 40px 15px 1fr 20px;
    row-gap: 10px;
    height: calc(100% - 20px);
    padding: 10px 20px;
  }
}
