.tabstrip {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
  
    .tabsmenu {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #f5f5f5;
  
      .tabmenu {
        display: flex;
        width: 100%;
        height: 32px;
        margin: 0;
        padding: 0;
        overflow: hidden;
        scroll-behavior: smooth;
        flex-direction: inherit;
  
        > li {
          flex-grow: 1;
          width: 150px;
          min-width: 150px;
          padding: 5px 0;
          list-style: none;
          text-align: center;
          border-bottom: 2px solid #ccc;
  
          &.active {
            border-bottom: 2px solid var(--primary);
  
            > button {
              color: var(--primary);
  
              > svg {
                path,
                polygon,
                rect {
                  fill: var(--primary);
                }
              }
            }
          }
  
          &.hidden {
            display: none;
          }
  
          &.disabled {
            cursor: not-allowed;
            opacity: 0.5;
            pointer-events: none;
          }
  
          > button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            padding: 5px 0 0 0;
            color: #999;
            font-size: 12px;
            font-family: inherit;
            border: 0;
            background: transparent;
            cursor: pointer;
  
            &:focus {
              outline: none;
            }
            > svg {
              width: 13px;
              height: 13px;
              margin-right: 5px;
            }
          }
        }
      }
  
      .menuList {
        > span {
          > svg {
            width: 13px;
            height: 13px;
            margin-right: 5px;
          }
        }
        > p {
          color: #999;
          font-size: 12px;
        }
      }
  
      > button > span {
        font-size: 20px;
      }
    }
  
    .tabcontent {
      flex: 1;
      padding: 8px;
      overflow: auto;
  
      .tabitem {
        height: 100%;
  
        &.active {
          display: block;
        }
  
        &.unactive {
          display: none;
        }
      }
    }
  
    .footer {
      padding: 10px 15px 10px 10px;
    }
  }
  
  .itemlist {
    display: flex;
    width: 130px;
  
    span {
      svg {
        width: 13px;
        height: 13px;
        margin-right: 5px;
      }
    }
  
    p {
      color: #999;
      font-size: 12px;
    }
  }
  