.wizardSkeleton {
  display: flex;
  flex-flow: row wrap;
  height: 100%;
  width: 100%;
  overflow: auto;

  > div {
    margin: 0px 10px 10px 0px;
  }
}
