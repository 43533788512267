.skeletonContainer {
  display: grid;
  grid-template-rows: 50px 50px 40px 40px 40px 25px;
  grid-template-columns: 1fr 1fr;
  row-gap: 15px;
  padding: 10px 15px;

  .field {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    row-gap: 3px;

    > div:nth-child(2) {
      grid-column: span 2;
    }
  }

  > span {
    grid-column: span 2;
  }

  > div {
    grid-column: span 2;
  }
}
