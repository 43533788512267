.container {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;

  .mainLoader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 100%;
  }

  .noQuestions {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    height: 100%;

    > p {
      margin-top: 5px;
      color: #cccccc;
    }
  }

  .question {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 6px;
    width: 438px;

    &__item {
      width: calc(80% - 24px);
      background-color: #f5f5f5;
      padding: 6px;
      border-radius: 4px;
    }

    &__option {
      width: calc(20% - 6px);
      background-color: #f5f5f5;
      margin-left: 6px;
      padding: 6px;
      text-align: center;
      border-radius: 4px;
      line-height: 20px;
    }

    &__buttons {
      margin-top: 20px;
      margin-left: auto;

      button {
        width: fit-content;
      }
    }
  }
}
