.viewSwitch {
  display: flex;
  flex-flow: row nowrap;
  height: 100%;

  svg {
    width: 21px;
    height: 21px;
    margin-left: 5px;
    cursor: pointer;

    fill: #f4f4f4;
  }

  .darken path {
    fill: #999999;
  }
}
