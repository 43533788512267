.details {
  display: grid;
  grid-template-rows: repeat(2, auto) 1fr auto;
  row-gap: 10px;
  height: calc(100% - 6px);
  padding: 6px 3px 0px 3px;
  background-color: inherit;
  overflow: auto;

  .subject {
    height: 15px;
    margin: 0;
    color: #666666;
    font-size: 13px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    button {
      height: 25px;
      width: fit-content;
      border: 0;
    }
  }
}
