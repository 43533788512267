.label {
  display: flex;
  gap: 3px;
  width: 95%;

  @media (max-width: 767px) {
    flex-direction: column;
  }

  h4 {
    color: var(--primary);
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 50%;
  }

  h5 {
    max-width: 100%;
    color: #333333;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
