.skeletonContainer {
  height: 100%;

  .case {
    display: grid;
    grid-template-rows: 35% 5% auto;
    row-gap: 10px;
    height: 100%;
  }
}
