.backdrop {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;

  &.display {
    z-index: 99999;
    opacity: 1;
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    height: 100% !important;
  }

  &.hidden {
    opacity: 0;
    transition: opacity 195ms cubic-bezier(0.4, 0, 0.2) 0ms;
    visibility: hidden;
  }
}
