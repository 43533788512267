.item {
  display: flex;
  position: relative;
  height: 90px;
  max-height: 90px;
  min-height: 90px;
  width: 100%;
  background: #f2f2f2;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #e8e8e8;
  }

  &.selectedItem {
    background-color: #e8e8e8;
  }

  .left {
    flex-basis: 75%;
    min-width: 0;
  }

  .right {
    flex-basis: calc(25% - 1px);
    flex: 1;
    min-width: 0;
    margin: 8px 0;
    border-left: solid 1px #ccc;
  }

  .selected {
    position: absolute;
    top: 35px;
    right: -11px;
    width: 0;
    height: 0;
    border-left: 15px solid #e8e8e8;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
  }
}
