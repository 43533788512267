.selectedCard {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  position: relative;
  flex-grow: 1;
  padding: 17.5px;
  border: 1px dashed #cdcccc;
  border-radius: 3px;

  &.folder {
    border-radius: 0 6px 6px 6px;
    margin-top: 16px;
  }

  &.folder:before {
    position: absolute;
    height: 17px;
    left: -1px;
    top: -17px;
    width: 50%;
    background-color: #ffffff;
    border: 1px dashed #cdcccc;
    border-bottom: none;
    border-radius: 6px 20px 0 0;
    content: '';
  }

  .image {
    width: 150px;
    height: 150px;
    border: 1px dashed #cdcccc;
    margin-bottom: 5px;
    border-radius: 50%;
  }

  .info {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;

    h6 {
      width: 100%;
      align-self: center;
      font-size: 15px;
      color: var(--primary);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      text-align: center;
    }

    p {
      display: -webkit-box;
      -webkit-line-clamp: 10;
      -webkit-box-orient: vertical;
      overflow: hidden;
      align-self: flex-start;
      font-size: 12px;
      color: #666666;
      text-align: left;
      @media (max-height: 600px) {
        -webkit-line-clamp: 3;
      }
    }
  }

  .counter {
    position: absolute;
    top: 6px;
    right: 6px;
  }
}
