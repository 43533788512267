.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-color: #ffffff;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  svg {
    width: 35px;
    height: 35px;
  }

  &.large svg {
    width: 80px;
    height: 80px;
  }

  &.folder {
    border-radius: 0;
    background-color: inherit;
    svg {
      width: 100% !important;
      height: 100% !important;
    }
  }
}
