.textView {
    position: relative;
    height: 100%;
    background-color: #ffffff;
  
    .expand {
      position: absolute;
      top: 50px;
      right: 35px;
      cursor: pointer;
  
      svg {
        height: 30px;
        width: 30px;
  
        &:hover {
          path {
            fill: #ebebeb;
          }
        }
      }
    }
  }
  