.projectStep {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  padding: 30px 10px 20px 10px;

  &.threeOrLess {
    justify-content: center;
    flex-grow: 1;
  }

  .projectsContainer {
    display: flex;
    flex-flow: row wrap;
    margin-top: 5px;
    overflow: auto;
  }
}
