.container {
  display: flex;
  flex: 1;
  height: 100%;
  overflow: auto;

  .form {
    display: flex;
    flex-flow: column nowrap;
    gap: 10px;
    margin-bottom: 10px;
    width: calc(100% - 5px);

    label {
      color: #676767;
      font-size: 12px;
    }

    .save {
      position: absolute;
      bottom: -32.5px;
      right: 0;

      > button {
        height: 25px;
        width: 100px;
      }
    }
  }
}
