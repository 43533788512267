.nopicture {
  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    > span {
      border-radius: 50%;
      font-size: 32px !important;
    }
  }
}

.picture {
  > span {
    height: 100%;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }
}
