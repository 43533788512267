.stepperContainer {
  display: flex;
  flex-flow: row nowrap;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 2px 1px 10px 0px #d4d4d4;
  border-radius: 3px;

  .stepper {
    position: relative;
    width: 135px;
    min-width: 135px;
    max-width: 135px;
    padding: 0px 15px 0 5px;

    .stepperOverride {
      :global(.k-progressbar-vertical) {
        grid-row: 3 / 15 !important;
      }

      :global(.k-i-warning) {
        display: none;
      }

      :global(.k-step-indicator) {
        width: 50px;
        height: 50px;

        > span {
          font-size: 28px;
        }
      }

      :global(.k-step-list-vertical ~ .k-progressbar) {
        left: 27px;
      }

      :global(.k-step-indicator):after {
        box-shadow: none;
        border-color: transparent;
      }

      .labelOverride {
        position: relative;

        .secondary {
          position: absolute;
          top: 18px;
          max-width: 67px;
          color: #999999;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        &.center {
          :global(.k-step-text) {
            position: relative;
            top: -10px;
          }

          .secondary {
            top: 7px;
          }
        }
      }
    }
  }

  .step {
    display: flex;
    flex-grow: 1;
    height: 100%;
    width: 0;
  }
}
