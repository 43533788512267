.caseStep {
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  width: 100%;
  padding: 30px 15px 35px 10px;

  @media (max-width: 601px) {
    padding: 0px 0px 35px 0px;
  }

  .form {
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    flex-grow: 1;
    width: calc(100% - 160px);
    /* Height calculated from subtracting padding and save button section (30px) */
    height: calc(100% - 90px);
    margin-bottom: 5px;
    padding: 55px 80px 5px 80px;
    background-color: #f2f2f2;

    @media (max-width: 601px) {
      width: calc(100% - 30px);
      /* Height calculated from subtracting padding and save button section (30px) */
      height: calc(100% - 65px);
      padding: 25px 15px 5px 15px;
    }

    .caseOptions {
      position: absolute;
      top: 20px;
      right: 80px;

      @media (max-width: 601px) {
        top: 5px;
        right: 5px;
      }
    }

    :global(.k-animation-container.k-animation-container-relative) {
      left: auto !important;
    }

    .attachments {
      width: 100%;
      margin-top: 10px;
    }
  }
}
