.closeCase {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;

  label {
    color: var(--primary);
  }

  button {
    margin-left: auto;
    margin-top: 10px;
    height: 25px;
    width: 100px;
  }
}
