.modal {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  position: relative;

  .loader {
    display: flex;
    justify-content: center;
    flex-flow: column;
    align-self: center;
    flex: 1;
  }

  .loading {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 28px;

    &.display {
      display: block;
    }
  }

  .tree {
    flex: 1;
    overflow: auto;
  }

  button {
    margin-left: auto;
    height: 25px;
    width: 100px;
  }
}
