.noData {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  color: #cccccc;

  svg {
    height: 150px;
    width: 100px;
  }
  span {
    margin-top: 10px;
  }
}
