.fields {
    padding: 0 10px;
  
    .section {
      display: grid !important;
      grid-template-columns: 1fr 1fr;
      column-gap: 5px;
      width: calc(100% - 5px);
      margin-bottom: 10px;
    }
  
    .field {
      margin-bottom: 5px;
      min-width: 0;
  
      &.fullColumn {
        grid-column: span 2;
      }
  
      @media (max-width: 975px) {
        grid-column: span 2;
      }
    }
  }
  