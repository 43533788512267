.square {
  display: inline-flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 60px;
  width: 35px;
  background-color: var(--primary);
  line-height: normal;
  padding: 3px 2px;
  border-radius: 2px;

  &.priority {
    background-color: #ff0000;
    color: #ffffff;

    > .exclamation {
      display: inline-flex;
      place-items: center;
      justify-content: center;
      position: absolute;
      bottom: -9px;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: inherit;
      font-size: 15px;
    }
  }

  &.novelty {
    background-color: #ffcc00;
    color: #ffffff;
  }

  .day {
    margin-bottom: 2px;
    font-size: 21px;
    border-bottom: 1px solid #ffffff;
    color: #ffffff;
  }

  .monthYear {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    font-size: 12px;
    color: #ffffff;
  }
}
