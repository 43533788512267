/* CSS Reset */
@import-normalize;

@font-face {
  font-family: 'Neusa Next';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./assets/fonts/neusanextstd-regular-webfont.eot');
  src: local('Neusa Next'), local('NeusaNext-Regular'),
    url('./assets/fonts/neusanextstd-regular-webfont.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/neusanextstd-regular-webfont.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./assets/fonts/neusanextstd-regular-webfont.woff')
      format('woff'),
    /* Modern Browsers */ url('./assets/fonts/neusanextstd-regular-webfont.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./assets/fonts/neusanextstd-regular-webfont.svg#NeusaNext');
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Neusa Next;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: normal;
}

::-webkit-scrollbar {
  width: 7px;
  height: 9px;
  background-color: #f0f0f0;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1);
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-corner {
  background-color: #f0f0f0;
}

:root {
  --primary: #f4846e;
  --rgb: rgba(244, 132, 110, 1);
}
