.listContainer {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;

  .list {
    display: flex;
    flex-flow: column nowrap;
    margin-top: 5px;
    overflow: auto;
  }
}
