@import '~@progress/kendo-theme-default/dist/all.scss';
@import 'assets/styles/KendoOverride.scss';

.app {
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.k-dialog-wrapper {
  z-index: 10000000000;
}
