.search {
  margin-right: 25px;
  align-self: center;
}

.helperButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 40px;
  background-color: #ffffff;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
  }
}

svg.service rect {
  stroke: #999999;
}
