.skeletonContainer {
  height: 100%;
  width: 100%;

  .case {
    display: grid;
    grid-template-rows: 10% 10% 10% auto 10%;
    row-gap: 10px;
    height: calc(100% - 25px);
    padding-top: 25px;

    .field {
      display: grid;
      grid-template-rows: 15px 1fr;
      row-gap: 10px;
      > div:first-child {
        width: 50%;
      }
    }
  }
}
