.textView {
  position: relative;
  height: 100%;
  background-color: #ffffff;

  :global(.k-tabstrip-top > .k-tabstrip-items-wrapper .k-item) {
    max-width: 260px;
    display: block;
    overflow: hidden;
  }

  :global(.k-tabstrip-top > .k-tabstrip-items-wrapper .k-item .k-link) {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 240px;
    display: inline-block;
  }

  .expand {
    position: absolute;
    top: 50px;
    right: 35px;
    cursor: pointer;
    z-index: 1;

    svg {
      height: 30px;
      width: 30px;

      &:hover {
        path {
          fill: #ebebeb;
        }
      }
    }
  }
}
