.fileWrap {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;

  .file {
    display: flex;
    flex-flow: row nowrap;
    position: relative;
    width: 100%;
    margin-top: 3px;

    .fileButton {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      color: #656565;
      background-color: #fff;
      border-width: 1px;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0.15);
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      > span {
        position: relative;
        .fileInput {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          opacity: 0;
          height: 100%;
        }
      }
    }

    &:focus-within .fileButton {
      border-color: var(--primary);
    }

    .fileText {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: none;
      flex-grow: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 100%;
      padding-right: 30px;
    }

    .delete {
      position: absolute;
      top: 7px;
      right: 8px;
      cursor: pointer;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  &.disabled {
    cursor: not-allowed;

    .fileButton {
      background-color: #eeeeee;
      color: #999999;
      outline: none;
      opacity: 0.6;
      filter: grayscale(0.1);
    }

    .fileText {
      background-color: #eeeeee;
      border: 1px solid #cccccc;
      color: #444444;
    }
  }
  .textLabel {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
  }
}
