.caseCreation {
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  height: 100%;
  overflow-x: hidden;
  > div:first-child {
    height: calc(100% - 65px);
  }

  @media (max-width: 601px) {
    > div:first-child {
      height: calc(100% - 35px);
    }
  }

  .helperButton {
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 30px;
    right: 15px;
    height: 40px;
    width: 40px;
    background-color: #ffffff;
    border: 1px solid #ccc;
    cursor: pointer;

    @media (max-width: 601px) {
      right: 5px;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.complete {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  height: 100%;

  .icon {
    font-size: 144px;
  }

  .message {
    font-size: 40px;
    font-weight: 300;

    color: #999999;

    @media (max-width: 601px) {
      font-size: 24px;
      text-align: center;
    }
  }
}
