.view {
  display: flex;
  flex-flow: column nowrap;
  height: calc(100% - 10px);
  padding: 5px;
  overflow: auto;

  .actions {
    display: flex;
    flex-flow: row nowrap;

    .add {
      display: flex;
      flex-flow: row nowrap;
      position: relative;
      color: var(--primary);
      cursor: pointer;

      .addText {
        font-size: 12px;
        margin-left: 5px;
      }

      input[type='file'] {
        position: absolute;
        width: 100%;
        opacity: 0;
      }
    }
  }

  .list {
    display: flex;
    flex-flow: column nowrap;
    flex-grow: 1;
    margin-top: 10px;
  }
}
