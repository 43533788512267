.counter {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  height: 15px;
  width: 60px;
  border-radius: 4px;
  background-color: #666666;
  padding: 1px;

  span {
    color: #ffffff;
    font-size: 9px;
  }
}
