.dropdownWrap {
  display: flex;
  flex-flow: column nowrap;

  .dropdown {
    margin-top: 3px;
    width: 100%;
    display: flex;
    align-items: center;

    &.error > span {
      border-color: #ee3557;
    }
  }

  &.disabled {
    cursor: not-allowed;

    .dropdown > span {
      background-color: #eeeeee;
      border: 1px solid #cccccc;
      color: #444444;
    }
  }
  .textLabel {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
  }
}
