.container {
  display: grid;
  grid-template-columns: 30px 1fr 30px;
  column-gap: 5px;

  > button {
    border: 0;
  }

  .slider {
    display: flex;
    flex-flow: nowrap;
    overflow: hidden;
    scroll-behavior: smooth;

    .tag {
      display: inline-flex;
      align-items: center;
      height: 18px;
      padding: 6px;
      background-color: #f2f2f2;
      border: 1px solid #c1c1c1;
      border-radius: 3px;
      font-size: 11px;
      text-transform: capitalize;
      margin-right: 10px;
    }
  }
}
