.statusLabel {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-evenly;
  height: 11px;
  width: 56px;
  padding: 3px;
  background-color: var(--primary);
  color: #ffffff;
  font-size: 10px;
  border-radius: 4px;
  text-transform: uppercase;
  line-height: 1;

  &.closedBG {
    background-color: #666666;
  }

  > span {
    font-size: 10px;
  }
}
