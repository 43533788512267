.alert {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  height: 300px;
  width: 700px;
  margin: 30px auto;
  background-color: #ffffff;

  @media (max-width: 900px) {
    width: 90%;
    height: initial;
    padding: 20px 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
  }

  .icon {
    font-size: 144px;

    @media (max-width: 900px) {
      font-size: 70px;
    }
  }

  .message {
    width: 500px;
    font-size: 40px;
    font-weight: 300;
    color: #999999;
    text-align: center;

    @media (max-width: 900px) {
      font-size: 20px;
      padding-top: 15px;
      width: 100%;
    }
  }
}

.alertWithbar {
  :global(.k-notification-content) {
    position: absolute;
  }
  span {
    :global(.k-notification-close-action) {
      padding-left: 35rem;
    }
  }
}
