.alert {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 5px;
  position: fixed;
  bottom: 20px;
  left: 50%;
  padding: 10px;
  transform: translate(-50%, 0);
  z-index: 10060;
  border-radius: 4px;
  border: 1px solid #444;
  background-color: whitesmoke;

  > span {
    white-space: nowrap;
  }
}
