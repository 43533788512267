.carousel {
  display: flex;
  flex-flow: row;
  position: relative;
  height: 100%;
  width: 100%;
  background-color: inherit;
  overflow: hidden;

  .slides {
    display: flex;
    flex-flow: row;
    flex: 1;
    background-color: inherit;

    .slide {
      display: flex;
      min-width: calc(100% - 70px);
      width: calc(100% - 70px);
      padding: 20px 35px;
      background-color: inherit;
      transition: 0.5s;
    }
  }

  .arrow {
    display: flex;
    align-items: center;
    position: absolute;
    top: 40%;
    z-index: 1;
    background-color: inherit;

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #a7a8ab;
      border-radius: 50%;
      cursor: pointer;

      svg {
        height: 20px;
        width: 20px;
      }
    }
  }
}
