.multiWrap {
  display: flex;
  flex-flow: column nowrap;

  .container {
    position: relative;

    .multicombobox {
      height: 34px;
      margin-top: 3px;
      overflow: hidden;
      display: flex;
      padding: 4px;

      > span[role="button"] {
        padding-right: 50px;
        padding-left: 5px;
      }

      &.error > div {
        border-color: rgba(243, 23, 0, 0.5);
      }

      > div {
        display: flex;
        flex-flow: nowrap;
        cursor: pointer;
        padding: 0 calc(1.4285714286em + 25px) 0 0;

        :global(.k-searchbar) {
          font-size: 12px;
          min-width: 20%;
        }

        :global(.k-i-loading) {
          height: 18px;
        }

        ul li {
          font-size: 0.75em;
          border-color: transparent;
          border-radius: 5em;
          color: rgba(0, 0, 0, 0.87);
          background-color: #e0e0e0;
          box-shadow: none;
          margin: 4px 4px 0 0;
        }

        :global(.k-clear-value) {
          display: none !important;
        }
      }
    }

    .counter {
      position: absolute;
      right: 0;
      top: 12px;
      font-size: 14px;
    }

    &:hover .counter {
      display: none;
    }
  }

  &.disabled {
    cursor: not-allowed;

    &:hover .multicombobox {
      height: unset;
      max-height: unset;
      overflow: unset;

      > div {
        padding: unset;

        :global(.k-searchbar) {
          min-width: unset;
          display: none;
        }
      }
    }

    .multicombobox > div {
      background-color: #eeeeee;
      border: 1px solid #cccccc;
      color: #444444;
    }
  }

  .textLabel {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
  }
}
