.toggle {
  display: flex;
  flex-flow: column nowrap;
  .buttonGroup {
    margin-top: 3px;
    .left {
      width: 60px;
      border-radius: 12.5px 0 0 12.5px !important;
    }

    .right {
      width: 60px;
      border-radius: 0 12.5px 12.5px 0 !important;
    }
  }

  .hint {
    color: #999999;
  }
}
