.fields {
  .section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 5px;
    width: calc(100% - 5px);
    margin-bottom: 10px;

    h4 {
      grid-column: span 2;
      color: var(--primary);
      font-size: 15px;
    }
    h5 {
      grid-column: span 2;
      color: #999999;
      font-size: 13px;
      margin-bottom: 10px;
    }
  }

  .field {
    margin-bottom: 5px;
    display: block;
    max-width: 100%;
    height: 100%;
    overflow: hidden;

    &.fullColumn {
      grid-column: span 2;
    }

    @media (max-width: 975px) {
      grid-column: span 2;
    }

    label {
      font-size: 12px;
      color: #676767;
    }
  }
}
