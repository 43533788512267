.numericWrap {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;

  .numeric {
    width: 100%;
    margin-top: 3px;

    &:focus-within > span {
      border-color: var(--primary);
    }

    &.error > span {
      border-color: #ee3557;
    }
  }

  &.disabled {
    cursor: not-allowed;
    .numeric > span {
      background-color: #eeeeee;
      border: 1px solid #cccccc;
      color: #444444;
    }
  }
  .textLabel {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
  }
}
