.list {
  display: flex;
  flex-flow: column nowrap;
  height: calc(100% - 20px);
  padding: 10px;
  background-color: #f2f2f2;
  overflow: auto;

  .survey {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    .left {
      display: flex;
      align-items: center;
      flex-direction: row;

      > span {
        margin-right: 10px;
      }

      .name {
        color: #666666;
        font-size: 12px;
      }
    }

    .right {
      display: flex;
      align-items: center;
      flex-direction: row;
      > span {
        color: #8da5a3;
        font-size: 12px;
        margin-right: 10px;
      }

      .button {
        width: fit-content;
        height: 25px;
        border: 0;
      }
    }
  }
}
