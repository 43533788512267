.editorContainer {
  display: flex;
  flex-flow: column;
  height: 100%;

  .textLabel {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;

    label {
      margin-bottom: 3px;
    }
  }

  .editor {
    flex: 1;

    iframe {
      height: 100% !important;
    }
  }

  .error {
    border-color: #f31700;
  }
}

.modalEditor {
  height: 100%;
  iframe {
    height: 100% !important;
  }
}
