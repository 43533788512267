.note {
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  margin-bottom: 20px;

  .noteIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    min-width: 30px;
    margin-top: 15px;
    margin-right: 10px;
    background-color: #cccccc;
    border-radius: 50%;

    svg {
      height: 15px;
      width: 15px;
    }
  }

  .main {
    display: flex;
    flex-flow: column nowrap;
    flex-grow: 1;
    position: relative;

    .details {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      margin-bottom: 3px;
      font-size: 12px;

      .autor {
        color: #333;
      }

      .date {
        color: #999999;
      }
    }

    .body {
      max-height: 55px;
      min-height: 30px;
      overflow: hidden;
      padding: 5px;
      background-color: #ffffff;
      border-radius: 3px;

      &.expanded {
        max-height: fit-content;
      }
    }

    .viewMore {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 10px;
      bottom: 0;
      height: 20px;
      width: 70px;
      color: var(--primary);
      background-image: linear-gradient(to bottom, #f2f2f2, #f2f2f2);
      font-size: 12px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .expand {
    position: absolute;
    top: 25px;
    right: 5px;
    cursor: pointer;

    svg {
      height: 30px;
      width: 30px;

      path {
        opacity: 0.7;
      }
    }
  }
}
