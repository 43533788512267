.notes {
  display: flex;
  flex-flow: column nowrap;
  height: calc(100% - 10px);
  padding: 5px;

  .actions {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    .add {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      color: var(--primary);
      cursor: pointer;

      .addText {
        font-size: 12px;
        margin-left: 5px;
      }
    }

    .filter {
      height: 25px;
      flex-basis: 30%;
      font-size: 12px;
      color: #555;
    }
  }
  .list {
    display: flex;
    flex-flow: column nowrap;
    flex-grow: 1;
    margin-top: 10px;
    overflow: auto;
  }
}
