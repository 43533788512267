.layout {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  height: calc(100% - 160px);
  padding: 80px 0;
  background-color: #f2f2f2;

  .logo {
    background-size: contain;
    margin-bottom: 50px;
  }

  .oops {
    font-size: 50px;
    color: #576574;
    margin-bottom: 13px;
  }

  .tryagain {
    color: #999999;
    font-size: 22px;
  }

  .error {
    width: 340px;
    height: 300px;
    background-size: cover;
  }
}
