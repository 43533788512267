.container {
  position: relative;
  width: 100%;

  .fileManager {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    background-color: #ffffff;
    border: 1px solid #e4e4e4;
    border-radius: 4px;
    padding: 0 10px;
    cursor: pointer;

    .count {
      color: #999999;
      font-size: 12px;

      &:hover {
        text-decoration: underline;
      }
    }

    .attach {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      position: relative;
      height: 20px;
      width: 115px;
      background-color: #999999;
      border-radius: 4px;

      .attachText {
        color: #ffffff;
        font-size: 10px;
        text-transform: uppercase;
      }
      .attachArrow {
        display: inline-flex;
        align-items: center;
        height: 100%;
        color: #ffffff;
      }

      input[type='file'] {
        position: absolute;
        width: 100%;
        opacity: 0;
      }
    }
  }
}

.fileList {
  display: flex;
  flex-flow: column;
  height: 180px;
  width: calc(100% - 2px);
  background-color: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  box-shadow: 1px 0px 14px 1px #d0d0d0;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    border-bottom: 1px solid #ccc;

    h4 {
      font-size: 14px;
      color: var(--primary);
    }
    .close {
      cursor: pointer;

      svg {
        height: 10px;
        width: 10px;

        polygon {
          fill: #cdcccc;
        }
      }
    }
  }
}
