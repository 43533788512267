.time {
  display: flex;
  flex-flow: column nowrap;
  > h3 {
    color: #acbdbb;
    font-size: 12px;
  }

  .counter {
    display: flex;
    height: 32px;

    .clock {
      display: grid;
      place-items: center;
      padding: 5px 3px;
      flex: 1;
      background-color: #cccccc;
      color: #ffffff;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    .timeSplit {
      align-items: center;
      background-color: #f2f2f2;
      color: #666666;
      display: flex;
      flex-flow: column nowrap;
      flex: 1;
      font-size: 14px;
      justify-content: center;
      padding: 0px 3px;

      &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        padding-right: 10px;
      }

      .value {
        height: 15px;
      }

      .type {
        text-transform: uppercase;
        font-size: 10px;
      }
    }

    .colon {
      display: flex;
      background-color: #f2f2f2;
      font-size: 14px;
      padding-top: 1px;
    }
  }
}
