.rating {
  display: flex;
  flex-flow: column nowrap;
  .container {
    display: inline-flex;
    height: 30px;
    width: fit-content;
    margin-top: 3px;
    background-color: #ffffff;

    .stars {
      border: 1px solid #fecc17;
    }

    .total {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      background-color: #fecc17;
      color: #ffffff;
      font-size: 14px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    .arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 3px;
      cursor: pointer;
    }
  }

  .hint {
    color: #999999;
  }
}
