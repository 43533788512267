.additionalMandatory {

    .text {
        padding: 0 0 10px 0;
    }

    .buttons {
        display: flex;
        justify-content: flex-end;
        padding: 10px 0 0 0;
        gap: 6px;
    }
}