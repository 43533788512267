.case {
  height: 100%;

  .addFieldsView {
    display: flex;
    flex-flow: column nowrap;
    flex: 1;
    height: 100%;

    h3 {
      font-size: 13px;
      color: #999999;
      margin-bottom: 12px;
    }

    .addFieldsBox {
      flex-grow: 1;
      overflow: auto;
    }

    .button {
      display: flex;
      justify-content: flex-end;
      height: 25px;

      button {
        width: 110px;
        margin-right: 10px;
      }
    }
  }
}
