.serviceStep {
  display: grid;
  grid-template-columns: 222px 1fr;
  grid-template-rows: 100%;
  column-gap: 10px;
  height: calc(100% - 50px);
  width: 100%;
  padding: 30px 10px 20px 10px;

  .selectedProject {
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: 100%;
    row-gap: 5px;
  }

  &.folderView {
    grid-template-columns: 222px 222px 1fr;
    grid-template-rows: auto auto minmax(0, 1fr);

    > div:first-child {
      grid-column: span 1;
      grid-row: 1/-1;
    }

    > div:nth-child(2) {
      grid-column: span 2;
    }

    > div:nth-child(3) {
      grid-row: span 2;
    }

    > div:nth-child(4) {
      grid-row: span 2;
    }
  }
}
