.container {
  display: flex;
  height: 29px;
  width: 100%;

  .breadcrumbs {
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    list-style: none;
    font-size: 12px;

    .crumb {
      font-size: 14px;
      cursor: pointer;
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 12px;
    }
    .crumb:hover {
      text-decoration: underline;
    }
    .separator {
      color: #999999;
      margin: auto 6px;
      user-select: none;
    }
    li {
      color: #999999;
      height: 15px;
    }

    li:last-of-type {
      color: var(--primary) !important;
    }
  }
}
