.instruction {
  display: flex;
  flex-direction: row;
  align-items: center;

  .numberContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    min-width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #999999;
    margin-right: 8px;

    .number {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 2.5px;
      color: #ffffff;
    }
  }

  .text {
    font-size: 14px;
    color: #666666;
    margin: 0;
  }
}
