.fileModal {
    .buttons {
      display: flex;
      justify-content: flex-end;
      margin-top: 25px;
  
      button {
        width: 90px;
        &:last-child {
          margin-left: 15px;
        }
      }
    }
  }
  