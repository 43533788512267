.send {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-left: auto;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.content_check {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-top: 10px;

  label {
    cursor: pointer;
  }
}
