.card {
  display: flex;
  flex-flow: column nowrap;
  gap: 5px;
  order: 2;
  position: relative;
  width: 180px;
  height: 120px;
  margin: 0px 10px 10px 0px;
  padding: 10px;
  background-color: #f7f7f7;
  border-radius: 3px;
  transition: all 300ms ease;
  cursor: pointer;

  &.disabled {
    opacity: 0.5;
  }

  &:hover {
    background-color: #e6e6e6;
  }

  .upper {
    display: flex;
    flex-flow: row nowrap;

    .image {
      width: 50px;
      min-width: 50px;
      height: 50px;
      margin-right: 5px;
    }

    h6 {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      align-self: center;
      font-size: 16px;
      color: #000000;

      &:hover {
        color: var(--primary);
      }
    }
  }

  > p {
    margin: 0;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 12px;
    color: #666666;
    text-align: left;
  }

  .link {
    display: flex;
    margin-top: auto;
  }
}
