.tabstrip {
  height: 100%;

  > ul {
    display: flex;
    justify-content: center;
    overflow: hidden;

    > li {
      flex-grow: 1;
      z-index: unset;

      &:global(.k-state-active) {
        border-bottom-color: unset !important;
        border-top-color: transparent !important;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        background-color: transparent !important;
      }

      > span {
        display: inline-block !important;
        text-align: center;
      }
    }
  }

  .tabContent {
    display: flex;
    width: auto;
    background-color: inherit !important;
    border: none !important;
    > div {
      width: 100%;
      height: calc(100% - 6px);
      > div {
        height: 100%;
        > div {
          height: 100%;
        }
      }
    }
  }
}
