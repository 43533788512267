.inputWrap {
  display: flex;
  flex-flow: column nowrap;

  .hint {
    color: #999999;
  }

  .input {
    width: 100%;
    margin-top: 3px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
  }

  &.disabled {
    cursor: not-allowed;

    .input {
      background-color: #eeeeee;
      border: 1px solid #cccccc;
      color: #444444;
    }
  }

  .textLabel {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
  }
}
