.dateWrap {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;

  .date {
    width: 100%;
    margin-top: 3px;

    &.placeholder input {
      color: #999999;
    }

    &.error > span {
      border-color: #ee3557;
    }

    &:focus-within > span {
      border-color: var(--primary);
    }
  }

  &.disabled {
    cursor: not-allowed;

    .date > span {
      border-color: #cccccc;
      > span > span {
        background-color: #eeeeee;
        color: #444444;
      }
    }
  }
  .textLabel {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
  }
}
