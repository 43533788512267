.stars {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100px;
  background-color: #ffffff;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  .star {
    padding-bottom: 4px;

    :global(.k-rating-container) {
      margin: 0;
    }

    :global(.k-rating-item) {
      padding: 0;

      > span {
        font-size: 20px;
      }
    }
  }
}

.container {
  display: inline-flex;
  height: 30px;

  .stars {
    border: 1px solid #fecc17;
  }

  .total {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    background-color: #fecc17;
    color: #ffffff;
    font-size: 14px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px;
    cursor: pointer;
  }
}

.popup {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  width: 240px;
  height: fit-content;
  background-color: #ffffff;
  border-radius: 4px;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 20px;
    padding: 5px;
    background-color: #efefef;

    h5 {
      color: var(--primary);
      font-size: 16px;
    }

    svg {
      height: 10px;
      width: 10px;
      cursor: pointer;
    }
  }

  .popUpContent {
    display: flex;
    flex-flow: column nowrap;
    padding: 10px;

    .stars {
      margin-bottom: 5px;

      .star {
        cursor: pointer;
      }
    }

    textarea {
      height: 100%;
      min-height: 100px;
      padding: 4px;
      margin-bottom: 8px;
      border: 1px solid #ccc;
      border-radius: 3px;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      font: inherit;
      font-size: 12px;
      resize: none;
      outline: none;
      resize: none;
    }

    .buttons {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      flex-flow: row nowrap;
      .button > button {
        height: 25px;
        width: 105px;
      }
    }
  }
}
