.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;

    svg {
      width: 32px;
      height: 32px;

      path {
        fill: #999999;
      }
    }
  }

  h2 {
    font-weight: 500;
    font-size: 20px;
    color: #999999;
    margin-left: 10px;
  }
}
