.article {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  background-color: #f2f2f2;

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    color: var(--primary);
    border-bottom: 2px solid var(--primary);

    svg {
      height: 15px;
      width: 15px;
      margin-right: 3px;
      path {
        fill: var(--primary);
      }
    }
    h3 {
      font-size: 12px;
    }
  }

  &.bare {
    background-color: inherit;
    grid-template-rows: 1fr;
    .header {
      display: none;
    }
  }

  .container {
    display: grid;
    grid-template-rows: auto 30px 1fr auto;
    row-gap: 5px;
    padding: 10px 20px;

    &.hasWords {
      grid-template-rows: auto 30px 1fr auto auto;
    }

    .subheader {
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 5px;
      border-bottom: 1px solid #dcdcdc;

      .details {
        display: flex;
        flex-direction: column;
        color: #6c6c6c;
        font-size: 12px;
      }
    }

    .description {
      max-height: 28px;
      margin: 0;
      color: #6c6c6c;
      font-size: 12px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .content {
      position: relative;
      background-color: #ffffff;
      border: 1px solid #cccccc;

      .expand {
        position: absolute;
        top: 25px;
        right: 35px;
        cursor: pointer;

        svg {
          height: 30px;
          width: 30px;

          &:hover {
            path {
              fill: #ebebeb;
            }
          }
        }
      }

      &.externalContent {
        background-color: inherit;
        border: none;

        .external {
          display: grid;
          grid-template-rows: 15px 1fr;
          height: 100%;

          .externalHint {
            margin: 0;
            font-size: 12px;
            color: #999999;

            span {
              color: var(--primary);
              text-decoration: underline;
              cursor: pointer;
            }
          }

          > iframe {
            margin-top: 5px;
            background-color: #ffffff;
            border: 1px solid #cccccc;
          }
        }

        .expand {
          top: 40px;
        }
      }
    }
  }
}
