.searchWrap {
  display: flex;
  flex-flow: column nowrap;

  > span {
    width: 100%;
  }

  .labelContainer {
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;

    > .textLabel {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 75%;

      > label {
        margin-right: 5px;
      }
    }

    > span {
      margin-right: 5px;
    }
  }

  &.disabled {
    cursor: not-allowed;

    .autoComplete {
      background-color: #eeeeee;
      border: 1px solid #cccccc;
      color: #444444;
    }
  }
}

.searchResult {
  display: flex;
  flex-flow: column nowrap;

  .title {
    color: var(--primary);
    font-size: 14px;
  }

  .detail {
    color: #999999;
    font-size: 12px;
  }
}
