.plaque {
  display: grid;
  column-gap: 20px;
  height: fit-content;
  grid-template-columns: 1fr 1fr;
  padding: 10px 20px;
  background-color: #ffffff;
  border-radius: 8px;

  @media (max-width: 767px) {
    padding: 0;
  }

  .column {
    display: flex;
    flex-flow: column nowrap;
    min-width: 0;
    gap: 5px;

    &:first-child {
      border-right: 1px solid #ccc;
    }

    .progress {
      display: flex;
      flex-flow: column nowrap;

      .progressLabel {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;
        .progressText {
          color: var(--primary);
          font-size: 12px;
        }

        .progressValue {
          font-size: 12px;
        }
      }

      .progressbar {
        height: 8px;
        width: 100%;
        border-radius: 3px;
        background-color: #ccc;
        > span {
          position: relative;
        }
        &.green > div {
          background-color: #008c00;
          border-color: #008c00;
        }
        &.yellow > div {
          background-color: #ffa300;
          border-color: #ffa300;
        }
        &.red > div {
          background-color: #ff0000;
          border-color: #ff0000;
        }
      }
    }

    .state {
      display: flex;
      margin: auto 0;
      padding: 3px 10px 0 8px;
      color: #666666;
      font-size: 12px;
      text-transform: uppercase;
      max-height: 35px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .service {
      color: var(--primary);
      font-size: 14px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-right: 5px;
    }

    .row {
      display: flex;
      justify-content: space-between;
      flex-flow: row wrap;
      gap: 5px;
    }

    .estimatedDate {
      display: grid;
      grid-template-columns: 1fr 1fr;
      background-color: #eff1f1;
      padding: 5px;
      font-size: 10px;
      color: #333333;
      border-radius: 4px;

      .date {
        text-align: center;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
