.container {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  overflow: auto;

  .file {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 30px;
    width: calc(100% - 10px);
    padding: 5px;
    border-bottom: 1px solid #ccc;
    color: #666666;

    &:hover {
      background-color: #e6e6e6;
    }

    .date {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      width: 15%;

      .full {
        max-width: 100%;
        font-size: 10px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .hour {
        max-width: 100%;
        font-size: 11px;
        font-weight: bold;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .text {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      flex: 1 1;
      max-width: 80%;
      min-width: 0;
      justify-content: flex-start;
      padding: 0;
      color: #666;
      font-size: 13px;
      text-decoration: none;
      cursor: pointer;

      .disabled {
        outline: none;
        opacity: 0.6;
        pointer-events: none;
        box-shadow: none;
      }

      .name {
        flex-grow: 1;
        justify-content: flex-start;
        width: fit-content;
        padding: 0;
        color: #666666;
        font-size: 12px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        text-decoration: none;
        cursor: pointer;
        border: 0;
        background-color: transparent;
        background-image: none;

        &:hover {
          color: var(--primary);
        }
      }

      .description {
        flex-grow: 1;
        margin: 0;
        font-size: 10px;
        color: #999999;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .right {
      width: 15%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      .size {
        font-size: 11px;
        white-space: nowrap;
      }

      svg {
        width: 15px;
        min-width: 15px;
        height: 15px;
        cursor: pointer;

        path {
          fill: #818285;
        }
      }
    }
  }

  .noAttachments {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;

    > span {
      color: #ccc;
      font-size: 12px;
    }
    svg {
      height: 40px;
      width: 40px;

      path {
        fill: #ccc !important;
      }
    }
  }
}
