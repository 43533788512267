.folder {
  display: flex;
  flex-flow: column nowrap;
  order: 1;
  position: relative;
  width: 180px;
  height: 109px;
  margin: 12px 10px 10px 0px;
  padding: 10px;
  background-color: #f7f7f7;
  border-radius: 0 6px 6px 6px;
  transition: all 300ms ease;
  cursor: pointer;

  &:before {
    position: absolute;
    top: -11px;
    left: 0px;
    height: 12px;
    width: 40%;
    background-color: #f7f7f7;
    border-radius: 6px 20px 0 0;
    transition: all 300ms ease;
    content: '';
  }

  &:hover:before,
  &:hover {
    background-color: #e6e6e6;
  }

  .upper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;

    .image {
      align-self: center;
      justify-self: center;
      width: 55px;
      min-width: 55px;
      height: 55px;
      min-height: 55px;
      background-color: #ffffff;
      border-radius: 50%;
    }
  }

  .lower {
    display: flex;
    flex-flow: column nowrap;
    flex-grow: 1;

    h6 {
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      align-self: center;
      color: #000000;
      font-size: 16px;
      text-align: center;
    }

    p {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      max-height: 60%;
      align-self: flex-start;
      text-align: left;
      color: #666666;
      font-size: 12px;
      margin-top: 3px;
      margin-bottom: 0;
    }
    .categoryParent {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      margin-top: auto;

      .newCase {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        width: 80px;
        height: 20px;
        border-radius: 40px;
        background-color: var(--primary);
        color: #ffffff;
        font-size: 8px;
        line-height: 1px;

        .newCaseIcon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 12px;
          height: 12px;
          background-color: #ffffff;
          border-radius: 50%;
          color: var(--primary);
          font-size: 17px;

          &:before {
            content: '\002B';
            padding-top: 3px;
          }
        }
      }
    }
  }

  .counter {
    position: absolute;
    top: 6px;
    right: 6px;
  }
}
