.inputWrap {
  display: flex;
  flex-flow: column nowrap;
  position: relative;

  .input {
    width: 100%;
    margin-top: 3px;

    &::-ms-reveal,
    &::-ms-clear {
      display: none;
    }
  }

  &.disabled {
    cursor: not-allowed;

    .input {
      background-color: #eeeeee;
      border: 1px solid #cccccc;
      color: #444444;
    }
  }

  .preview {
    position: absolute;
    right: 5px;
    bottom: 7px;
    color: #666666;
    cursor: pointer;
  }
}
